<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">基本信息</p>
      <el-form class="mt-24" label-width="120px" :rules="rules" ref="act_form" :model="act_form">
        <el-form-item label="活动标题" prop="activityTitle">
          <el-input class="form-input" placeholder="请输入活动标题" v-model="act_form.activityTitle"></el-input>
        </el-form-item>
        <el-form-item label="活动标题" prop="storeId">
          <el-select
            clearable
            placeholder="选择门店"
            class="form-input"
            v-model="act_form.storeId"
          >
            <el-option
              v-for="(item,index) in store_list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动主办方" prop="organisers">
          <el-input class="form-input" placeholder="请输入活动主办方" v-model="act_form.organisers"></el-input>
        </el-form-item>
        <el-form-item label="活动举办时间" prop="startTimeStr">
          <!-- <el-date-picker
            v-model="act_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            end-placeholder="结束日期"
            @change="getDateTime('act')"
          ></el-date-picker>-->
          <el-date-picker
            v-model="act_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
            @change="getDateTime('act')"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动举办地点" prop="address">
          <el-input class="form-input" v-model="act_form.address" placeholder="请输入活动举办地点"></el-input>
        </el-form-item>
        <el-form-item label="活动类型" :prop="act_form.activityType==2?'memberLevel':'activityType'">
          <el-radio v-model="act_form.activityType" :label="1">普通活动</el-radio>
          <el-radio v-model="act_form.activityType" :label="2">会员活动</el-radio>
          <el-select
            placeholder="请选择会员等级"
            v-model="act_form.memberLevel"
            v-show="act_form.activityType==2"
          >
            <el-option
              v-for="(item,index) in grade_list"
              :key="index"
              :label="item.level"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动主图" prop="activityImgs">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'690*420'"
            :imgsPreview="mainImgStr"
            @getImgData="getImgData"
          />
        </el-form-item>
        <el-form-item label="活动辅图" prop="subImgs">
          <UploadPics
            :uploadType="'pics'"
            :imgLimit="6"
            :imgSize="'690*420'"
            :imgsPreview="subImgStr"
            @getImgData="getImgData"
          />
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">其他信息</p>
        <el-form-item label="报名上限人数" prop="enrollmentLimit">
          <el-input-number
            controls-position="right"
            v-model.number="act_form.enrollmentLimit"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="虚拟点赞人数">
          <el-input-number controls-position="right" v-model.number="act_form.virtualLike" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item
          label="报名条件"
          :prop="act_form.enrollCondition==2?'memberPoints':'enrollCondition'"
        >
          <el-radio v-model="act_form.enrollCondition" :label="1">免费报名</el-radio>
          <el-radio v-model="act_form.enrollCondition" :label="2">积分报名</el-radio>
          <el-input-number
            controls-position="right"
            :min="0"
            v-model.number="act_form.memberPoints"
            v-show="act_form.enrollCondition==2"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="报名时间" prop="registrationStartTimeStr">
          <el-date-picker
            v-model="sign_date"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
            @change="getDateTime('reg')"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动详情介绍">
          <tinymce v-model="act_form.activityDescribe" :height="300" />
        </el-form-item>
      </el-form>
      <!-- 按钮区域 -->
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="$router.go(-1)">取消</el-button>
        <el-button class="form-button" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics";
import { getStoreList } from "@/api/online_department_store/commodity_manage"
import {getMemberActivetyDetail, subMemberActivetyForm } from "@/api/members/members_activity";

export default {
  components: {
    Tinymce,
    UploadPics
  },
  data() {
    return {
      act_form: {
        activityTitle: "",
        organisers: "",
        startTimeStr: "",
        endTimeStr: "",
        // activityTime: "",
        address: "",
        memberLevel: "",
        activityType: 1,
        subImgs: "",
        activityImgs: "",
        enrollCondition: 1,
        enrollmentLimit: 1,
        virtualLike: 0,
        memberPoints: "",
        storeId: '',
        registrationStartTimeStr: "",
        registrationEndTimeStr: "",
        // registrationTime: "",
        activityDescribe: ``
      },
      rules: {
        activityTitle: [
          { required: true, message: "活动标题不能为空", trigger: "blur" }
        ],
        storeId:  [
          { required: true, message: "门店不能为空", trigger: "blur" }
        ],
        organisers: [
          { required: true, message: "活动主办方不能为空", trigger: "blur" }
        ],
        startTimeStr: [
          { required: true, message: "活动举办时间不能为空", trigger: "blur" }
        ],
        address: [
          { required: true, message: "活动举办地点不能为空", trigger: "blur" }
        ],
        activityType: [
          { required: true, message: "活动类型不能为空", trigger: "blur" }
        ],
        memberLevel: [
          { required: true, message: "会员等级不能为空", trigger: "blur" }
        ],
        enrollmentLimit: [
          { required: true, message: "报名人数上限不能为空", trigger: "blur" },
          { type: "number", message: "报名人数只能为数字", trigger: "blur" }
        ],
        enrollCondition: [
          { required: true, message: "报名条件不能为空", trigger: "blur" }
        ],
        memberPoints: [
          { required: true, message: "积分不能为空", trigger: "blur" },
          { type: "number", message: "积分只能为数字", trigger: "blur" }
        ],
        subImgs: [
          { required: true, message: "活动辅图不能为空", trigger: "change" }
        ],
        activityImgs: [
          { required: true, message: "活动主图不能为空", trigger: "change" }
        ],
        registrationStartTimeStr: [
          { required: true, message: "报名时间不能为空", trigger: "blur" }
        ]
      },
      // 活动举办日期时间、报名时间
      act_date: [],
      act_time: {
        actStart: "",
        actEnd: ""
      },
      register_time: {
        regStart: "",
        regEnd: ""
      },
      sign_date: [],
      // 会员等级列表
      grade_list: [
        {
          level: "金卡会员",
          value: 18
        },
        {
          level: "钻石会员",
          value: 20
        },
        {
          level: "铂卡会员",
          value: 23
        }
      ],
      store_list: [],
      addFlag: true,
      activityId: null,
      mainImgStr: "",
      subImgStr: ""
    };
  },
  mounted() {
    this.handleOpeType();
    this.getStores()
  },
  methods: {
    getImgData(imgData, type) {
      if (type == "pics") this.act_form.subImgs = imgData.subImgs;
      if (type == "main") this.act_form.activityImgs = imgData.mainImg;
    },
    //判断操作类型-新增/编辑
    handleOpeType() {
      let params = this.$route.params;
      this.addFlag =
        params.addFlag != undefined
          ? params.addFlag
          : sessionStorage.getItem("ADD_FLAG");
      if (params.addFlag != undefined)
        sessionStorage.setItem("ADD_FLAG", params.addFlag);
      if (params.activityId)
        sessionStorage.setItem("ACTIVITY_ID", params.activityId);
      if (String(this.addFlag) == "false") {
        this.activityId =
          params.activityId || sessionStorage.getItem("ACTIVITY_ID");
        this.getActData(this.activityId);
      }
    },
    //获取活动详情
    getActData(id) {
      getMemberActivetyDetail(id).then(res => {
        if (res.data.code == 200) {
          this.act_form = res.data.body;
          // let act_time = this.act_form.activityTime.split("-");
          // let reg_time = this.act_form.registrationTime.split("-");
          this.act_date = [this.act_form.startTime, this.act_form.endTime];
          this.sign_date = [
            this.act_form.registrationStartTime,
            this.act_form.registrationEndTime
          ];
          if (this.act_date && this.act_date.length > 1)
            this.getDateTime("act");
          if (this.sign_date && this.sign_date.length > 1)
            this.getDateTime("reg");
          this.mainImgStr = this.act_form.activityImgs;
          this.subImgStr = this.act_form.subImgs;
        }
      })
    },
    // 获取门店列表
    getStores() {
      getStoreList().then(res => {
        this.store_list = res.data.body.list;
      })
    },
    getContent(content) {
      this.act_form.activityDescribe = content;
    },
    getDateTime(type) {
      if (type == "act") {
        this.act_form.startTimeStr = this.act_date[0];
        this.act_form.endTimeStr = this.act_date[1];
        // this.act_form.startTimeStr = this.act_date[0].split(" ")[0];
        // this.act_form.endTimeStr = this.act_date[1].split(" ")[0];
        // this.act_form.activityTime = `${this.act_date[0].split(" ")[1]}-${
        //   this.act_date[1].split(" ")[1]
        // }`;
      } else {
        this.act_form.registrationStartTimeStr = this.sign_date[0];
        this.act_form.registrationEndTimeStr = this.sign_date[1];
        // this.act_form.registrationTime = `${this.sign_date[0].split(" ")[1]}-${
        //   this.sign_date[1].split(" ")[1]
        // }`;
      }
    },
    timeCheck() {
      let timeNow = new Date().getTime();
      if (new Date(this.act_date[1]).getTime() < timeNow) {
        this.$message({
          message: "活动举办时间应晚于当前时间",
          type: "error"
        });
        return false;
      }
      if (new Date(this.sign_date[1]).getTime() < timeNow) {
        this.$message({
          message: "报名时间应晚于当前时间",
          type: "error"
        });
        return false;
      }
      if (
        new Date(this.act_date[0]).getTime() <
        new Date(this.sign_date[1]).getTime()
      ) {
        this.$message({
          message: "报名截止时间应早于活动开始时间",
          type: "error"
        });
        return false;
      }
      return true;
    },
    //提交
    submit() {
      // // console.log(this.act_form, "this.act_form");
      // return;
      if (!this.timeCheck()) return;
      this.$refs["act_form"].validate(valid => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning"
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = this.act_form;
      if (String(this.addFlag) == "false") data.activityId = this.activityId;
      subMemberActivetyForm(data, String(this.addFlag) == "false" ? 1 : 0).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.$router.go(-1);
      })
    }
  }
};
</script>